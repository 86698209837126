import { normalize } from 'normalizr';
import sortBy from 'lodash/sortBy';
import * as schema from './schema';
import { request } from '../utils/axios';
import { getLangHeaders, localeForCatalog } from '../utils/common-methods';
import {
  FETCH_CROP_DETAILS_ERROR,
  FETCH_CROP_DETAILS_SUCCESS,
  FETCH_CROP_DETAILS_LOADING,
  FETCH_VARIETIES_LOADING,
  FETCH_VARIETIES_SUCCESS,
  FETCH_VARIETIES_ERROR
} from './types';

export function fetchCropLoading(value) {
  return {
    type: FETCH_CROP_DETAILS_LOADING,
    payload: value
  };
}
export function fetchCropSuccess(crops) {
  return {
    type: FETCH_CROP_DETAILS_SUCCESS,
    payload: normalize(crops, schema.cropsListSchema)
  };
}
export function fetchCropError(error) {
  return {
    type: FETCH_CROP_DETAILS_ERROR,
    error
  };
}

export const fetchCropDetails = () => {
  return (dispatch) => {
    dispatch(fetchCropLoading());
    return request
      .get(`/v2/crops?showDeleted=false${localeForCatalog('&')}`, {
        headers: getLangHeaders(),
        useCache: true
      })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        const sortedCrops = sortBy(res.data.content, (crop) => crop.name.toLowerCase()).filter(
          (crop) => crop.name.trim().length > 0
        );
        dispatch(fetchCropSuccess(sortedCrops));
        return res;
      })
      .catch((error) => {
        dispatch(fetchCropError(error));
      });
  };
};

export function fetchVarietiesLoading() {
  return {
    type: FETCH_VARIETIES_LOADING
  };
}

export function fetchVarietiesSuccess(orgs) {
  return {
    type: FETCH_VARIETIES_SUCCESS,
    payload: orgs
  };
}
export function fetchVarietiesError(error) {
  return {
    type: FETCH_VARIETIES_ERROR,
    error
  };
}

export const fetchVarieties = () => {
  return (dispatch) => {
    dispatch(fetchVarietiesLoading());
    return request
      .get(`/v2/varieties${localeForCatalog('?')}`, {
        headers: getLangHeaders(),
        useCache: true
      })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(fetchVarietiesSuccess(res.data.content));
        return res;
      })
      .catch((error) => {
        dispatch(fetchVarietiesError(error));
      });
  };
};
