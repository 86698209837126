import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import {
  SET_ORG_SEASONS_LOADING,
  SET_ORG_SEASONS_SUCCESS,
  SET_ORG_SEASONS_ERROR,
  EDIT_SEASON_LOADING,
  EDIT_SEASON_SUCCESS,
  EDIT_SEASON_ERROR,
  CREATE_SEASON_LOADING,
  CREATE_SEASON_SUCCESS,
  CREATE_SEASON_ERROR,
  RESET_CREATE_SEASON_FLAG,
  RESET_EDIT_SEASON_FLAG,
  DELETE_SEASON_BY_ID_LOADING,
  DELETE_SEASON_BY_ID_SUCCESS,
  DELETE_SEASON_BY_ID_ERROR,
  SET_EDIT_SEASON_SUCCESS,
  SET_SEASON_BY_ID_SUCCESS,
  SET_SEASON_BY_ID_LOADING,
  SET_SEASON_BY_ID_ERROR,
  FETCH_SEASONS_BY_PROP_ID_SUCCESS,
  FETCH_SEASONS_BY_PROP_ID_ERROR,
  FETCH_SEASONS_BY_PROP_ID_LOADING,
  SET_SEASON_BY_PROPERTY,
  SET_SEASON_BY_PROPERTY_LOADING
} from '../actions/types';

let initialState = {
  data: {
    entities: {},
    result: []
  },
  seasonFieldWithFieldIdsAsKey: {
    entities: {},
    result: []
  },
  seasonsByProperty: [],
  seasonsByPropertyLoading: false,
  loading: false,
  error: null,
  createSeasonLoading: false,
  createSeasonSuccess: false,
  createSeasonError: null,
  editSeasonSuccess: false,
  editSeasonError: null,
  editSeasonLoading: false,
  deleteSeasonLoading: false,
  deleteSeasonError: null,
  deleteSeasonSucces: true,
  seasonsByPropIdLoading: false,
  seasonsByPropIdError: null,
  seasonsByPropId: []
};

export const seasonsList = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORG_SEASONS_LOADING:
      return {
        ...state,
        loading: true,
        error: null
      };
    case SET_ORG_SEASONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: { ...state.data, ...action.payload }
      };
    // case SET_SEASON_FIELDS_WITH_FIELD_IDS_AS_KEY:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: null,
    //     seasonFieldWithFieldIdsAsKey: { ...state.seasonFieldWithFieldIdsAsKey, ...action.payload }
    //   };
    case SET_ORG_SEASONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case FETCH_SEASONS_BY_PROP_ID_LOADING:
      return {
        ...state,
        seasonsByPropIdLoading: true,
        seasonsByPropIdError: null
      };
    case FETCH_SEASONS_BY_PROP_ID_SUCCESS:
      return {
        ...state,
        seasonsByPropIdLoading: false,
        seasonsByPropIdError: null,
        seasonsByPropId: action.payload
      };
    case FETCH_SEASONS_BY_PROP_ID_ERROR:
      return {
        ...state,
        seasonsByPropIdLoading: false,
        seasonsByPropIdError: action.err
      };
    case SET_SEASON_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
        error: null
      };
    case SET_SEASON_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: {
          entities: {
            seasons: { ...state.data.entities.seasons, ...action.payload.entities.seasons }
          },
          result: uniq([...state.data.result, action.payload.result])
        }
      };
    case SET_SEASON_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case CREATE_SEASON_LOADING:
      return {
        ...state,
        createSeasonLoading: true,
        createSeasonError: null,
        createSeasonSuccess: false
      };

    case CREATE_SEASON_SUCCESS:
      return {
        ...state,
        createSeasonLoading: false,
        createSeasonError: null,
        createSeasonSuccess: true,
        data: {
          entities: {
            seasons: { ...state.data.entities.seasons, ...action.payload.entities.seasons }
          },
          result: [...state.data.result, action.payload.result]
        }
      };
    case CREATE_SEASON_ERROR:
      return {
        ...state,
        createSeasonLoading: false,
        createSeasonSuccess: false,
        createSeasonError: action.error
      };
    case RESET_CREATE_SEASON_FLAG:
      return {
        ...state,
        createSeasonSuccess: false,
        createSeasonError: null
      };
    case EDIT_SEASON_LOADING:
      return {
        ...state,
        editSeasonLoading: true,
        editSeasonSuccess: false,
        editSeasonError: null
      };
    case EDIT_SEASON_SUCCESS:
      let editedProperties = omit(state.data.entities.seasons, [action.payload.id]);
      return {
        ...state,
        data: {
          ...state.data,
          entities: {
            seasons: { ...editedProperties, [action.payload.id]: action.payload }
          }
        },
        editSeasonLoading: false,
        editSeasonSuccess: true,
        editSeasonError: null
      };
    case EDIT_SEASON_ERROR:
      return {
        ...state,
        editSeasonLoading: false,
        editSeasonSuccess: false,
        editSeasonError: action.error
      };
    case RESET_EDIT_SEASON_FLAG:
      return {
        ...state,
        editSeasonLoading: false,
        editSeasonSuccess: false,
        editSeasonError: null
      };
    case SET_EDIT_SEASON_SUCCESS:
      return {
        ...state,
        editSeasonLoading: false,
        editSeasonSuccess: true,
        editSeasonError: null
      };
    case DELETE_SEASON_BY_ID_LOADING:
      return {
        ...state,
        deleteSeasonLoading: true
      };
    case DELETE_SEASON_BY_ID_SUCCESS:
      let deletedSeasons = omit(state.data.entities.seasons, [action.payload.seasonId]);
      let result = state.data.result.filter((id) => id !== action.payload.seasonId);
      return {
        ...state,
        deleteSeasonLoading: false,
        deleteSeasonError: null,
        deleteSeasonSucces: true,
        data: {
          entities: { seasons: deletedSeasons },
          result: result
        }
      };
    case DELETE_SEASON_BY_ID_ERROR:
      return {
        ...state,
        deleteSeasonLoading: false,
        deleteSeasonError: action.error,
        deleteSeasonSucces: false
      };
    case SET_SEASON_BY_PROPERTY:
      return {
        ...state,
        seasonsByPropertyLoading: false,
        seasonsByProperty: action.payload
      };
    case SET_SEASON_BY_PROPERTY_LOADING:
      return {
        ...state,
        seasonsByPropertyLoading: true
      };
    default:
      return state;
  }
};
