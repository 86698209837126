import { normalize } from 'normalizr';
import * as schema from './schema';
import { request } from '../utils/axios';
import { getHeaders } from '../utils/common-methods';
import { fetchCropDetails } from './crops';
import {
  SET_ORGANIZATIONS_LIST_LOADING,
  SET_ORGANIZATIONS_LIST_SUCCESS,
  SET_ORGANIZATIONS_LIST_ERROR,
  CREATE_ORG_LIST_SUCCESS,
  CREATE_ORG_LIST_ERROR,
  CREATE_ORG_LIST_LOADING,
  EDIT_ORG_LIST_SUCCESS,
  EDIT_ORG_LIST_ERROR,
  EDIT_ORG_LIST_LOADING,
  DELETE_ORG_LIST_ITEM_RESET,
  DELETE_ORG_LIST_ITEM_LOADING,
  DELETE_ORG_LIST_ITEM_SUCCESS,
  DELETE_ORG_LIST_ITEM_ERROR,
  UPLOAD_ORG_LIST_ITEM_AVATAR_LOADING,
  UPLOAD_ORG_LIST_ITEM_AVATAR_SUCCESS,
  UPLOAD_ORG_LIST_ITEM_AVATAR_ERROR,
  REMOVE_ORG_LIST_ITEM_AVATAR_LOADING,
  REMOVE_ORG_LIST_ITEM_AVATAR_SUCCESS,
  REMOVE_ORG_LIST_ITEM_AVATAR_ERROR
} from './types';

export function setOrgListLoading() {
  return {
    type: SET_ORGANIZATIONS_LIST_LOADING
  };
}

export function setOrgListSuccess(orgsList) {
  return {
    type: SET_ORGANIZATIONS_LIST_SUCCESS,
    payload: normalize(orgsList, schema.orgsListSchema)
  };
}

export function setOrgListError(error) {
  return {
    type: SET_ORGANIZATIONS_LIST_ERROR,
    payload: error
  };
}

export const fetchOrgsList = () => {
  return (dispatch, getState) => {
    const {
      orgsList: {
        data: { entities },
        loading = false,
        isFetchOrgListSuccess = false
      }
    } = getState();

    if (
      (entities !== undefined && entities !== null && Object.keys(entities).length !== 0) ||
      loading === true ||
      isFetchOrgListSuccess === true
    ) {
      return;
    }

    dispatch(setOrgListLoading());
    request
      .get(`/v2/orgs`, {
        headers: getHeaders()
      })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(setOrgListSuccess(res.data.content));
        dispatch(fetchCropDetails());
      })
      .catch((error) => {
        dispatch(setOrgListError(error));
      });
  };
};

export function createOrgListLoading() {
  return {
    type: CREATE_ORG_LIST_LOADING
  };
}

export function createOrgListSuccess(orgs) {
  return {
    type: CREATE_ORG_LIST_SUCCESS,
    payload: normalize(orgs, schema.orgs)
  };
}

export function createOrgListError(error) {
  return {
    type: CREATE_ORG_LIST_ERROR,
    error
  };
}

export const createOrgList = (org) => {
  return (dispatch) => {
    dispatch(createOrgListLoading(true));
    return request({
      method: 'post',
      url: `/v2/orgs`,
      headers: getHeaders(),
      data: org
    })
      .then((res) => {
        if (res.error) {
          return res.error;
        }
        dispatch(createOrgListSuccess(res.data));
        return res;
      })
      .catch((error) => {
        dispatch(createOrgListError(error));
        return Promise.reject(error);
      });
  };
};

export function editOrgListLoading() {
  return {
    type: EDIT_ORG_LIST_LOADING
  };
}

export function editOrgListSuccess(orgs) {
  return {
    type: EDIT_ORG_LIST_SUCCESS,
    payload: normalize(orgs, schema.orgs)
  };
}

export function editOrgListError(error) {
  return {
    type: EDIT_ORG_LIST_ERROR,
    error
  };
}

export const editOrgList = (org) => {
  return (dispatch) => {
    dispatch(editOrgListLoading(true));
    return request({
      method: 'put',
      url: `/v2/orgs/${org.id}`,
      headers: getHeaders(),
      data: org
    })
      .then((res) => {
        if (res.error) {
          return res.error;
        }
        dispatch(editOrgListSuccess(res.data));
        return res;
      })
      .catch((error) => {
        dispatch(editOrgListError(error));
        return Promise.reject(error);
      });
  };
};

export const deleteOrgListItemReset = () => {
  return {
    type: DELETE_ORG_LIST_ITEM_RESET
  };
};

export const deleteOrgListItemLoading = () => {
  return {
    type: DELETE_ORG_LIST_ITEM_LOADING
  };
};

export const deleteOrgListItemSuccess = (payload) => {
  return {
    type: DELETE_ORG_LIST_ITEM_SUCCESS,
    payload
  };
};

export const deleteOrgListItemError = (error) => {
  return {
    type: DELETE_ORG_LIST_ITEM_ERROR,
    error
  };
};

export const deleteOrgListItem = (org) => {
  return (dispatch) => {
    dispatch(deleteOrgListItemLoading());
    request({
      method: 'delete',
      url: `/v2/orgs/${org.id}`,
      headers: getHeaders()
    })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(deleteOrgListItemSuccess(org));
      })
      .catch((error) => {
        dispatch(deleteOrgListItemError(error));
      });
  };
};

export const uploadOrgListItemAvatarLoading = () => {
  return {
    type: UPLOAD_ORG_LIST_ITEM_AVATAR_LOADING
  };
};

export const uploadOrgListItemAvatarSuccess = (payload) => {
  return {
    type: UPLOAD_ORG_LIST_ITEM_AVATAR_SUCCESS,
    payload
  };
};

export const uploadOrgListItemAvatarError = (error) => {
  return {
    type: UPLOAD_ORG_LIST_ITEM_AVATAR_ERROR,
    error
  };
};

export const uploadOrgListItemAvatar = (org, file) => {
  return (dispatch) => {
    dispatch(uploadOrgListItemAvatarLoading());
    const data = new FormData();
    data.append('file', file);
    return request({
      method: 'post',
      url: `/v2/orgs/${org.id}/picture`,
      headers: { ...getHeaders(), 'Content-Type': 'multipart/form-data' },
      data
    })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(uploadOrgListItemAvatarSuccess({ org, res }));
      })
      .catch((error) => {
        dispatch(uploadOrgListItemAvatarError(error));
      });
  };
};

export const removeOrgListItemAvatarLoading = () => {
  return {
    type: REMOVE_ORG_LIST_ITEM_AVATAR_LOADING
  };
};

export const removeOrgListItemAvatarSuccess = (payload) => {
  return {
    type: REMOVE_ORG_LIST_ITEM_AVATAR_SUCCESS,
    payload
  };
};

export const removeOrgListItemAvatarError = (error) => {
  return {
    type: REMOVE_ORG_LIST_ITEM_AVATAR_ERROR,
    error
  };
};

export const deleteOrgListItemAvatar = (org) => {
  return (dispatch) => {
    dispatch(removeOrgListItemAvatarLoading());
    request({
      method: 'delete',
      url: `/v2/orgs/${org.id}/picture`,
      headers: getHeaders()
    })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(removeOrgListItemAvatarSuccess(org));
      })
      .catch((error) => {
        dispatch(removeOrgListItemAvatarError(error));
      });
  };
};
