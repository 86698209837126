import axios from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';
import { isWebUri } from 'valid-url';

export const headers = {
  'Content-Type': 'application/x-www-form-urlencoded',
  authorization: 'Basic c3RyaXgtdWk6'
};

export const getAuthHeaders = {
  'Content-Type': 'application/x-www-form-urlencoded'
};

const urlToOverride = localStorage.getItem('baseURL');
let baseURL = process.env.REACT_APP_API_URL;

if (isWebUri(urlToOverride)) {
  baseURL = urlToOverride;
}

export const request = axios.create({
  baseURL,
  headers: { 'Cache-Control': 'no-cache' },
  // cache will be enabled by default
  adapter: cacheAdapterEnhancer(axios.defaults.adapter, {
    enabledByDefault: false,
    cacheFlag: 'useCache'
  })
  // timeout: 1000,
  // headers
});
