import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const PRIVATE_ROUTE_PREFIX_BACKWARD = 'app/';
export const LANDING_ORG_ROUTE = `${PRIVATE_ROUTE_PREFIX_BACKWARD}landing-organization`;
export const FARM_ROUTE = 'property';
export const SEASON_ROUTE = 'season';
const PRIVATE_ROUTE_PREFIX_FORWARD = '/app';

export const URL_CONSTANTS = {
  ROOT: () => '/',
  LOGIN: () => '/login',
  SIGNUP_1: () => '/sign-up-1',
  SIGNUP_2: () => '/sign-up-2',
  RECOVERY_PASSWORD: () => '/recovery-password',
  RESET_PASSWORD: () => '/reset-password',
  REVIEW_PAGE: () => '/app/review-page',
  NO_ACCESS: () => '/app/no-access',
  HALL_OF_ORG: () => `${PRIVATE_ROUTE_PREFIX_FORWARD}/hall-of-org`,
  TERMS_AND_CONDITIONS: () => '/terms-and-conditions',
  SETTINGS: () => `${PRIVATE_ROUTE_PREFIX_FORWARD}/settings`,
  MYSERVICES: ({ orgId }) => `${PRIVATE_ROUTE_PREFIX_FORWARD}/${orgId}/my-services`,
  RESOURCES: ({ orgId }) => `/${LANDING_ORG_ROUTE}/${orgId}/resources`,

  LANDING_ORGANIZATION: ({ orgId }) => `/${LANDING_ORG_ROUTE}/${orgId}`,
  CREATE_FARM: ({ orgId }) => `/${LANDING_ORG_ROUTE}/${orgId}/create-farm`,
  EDIT_FARM: ({ orgId, farmId }) => `/${LANDING_ORG_ROUTE}/${orgId}/edit-farm/${farmId}`,
  LANDING_FARM: ({ orgId, farmId }) => `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}`,

  ALL_FIELDS: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/all-fields`,
  CREATE_TASK: ({ orgId, farmId }) => `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/task`,
  SELECT_TASK_TYPE: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/select-task-types`,
  EDIT_TASK: ({ orgId, farmId, taskId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/task/${taskId}`,
  CREATE_FIELDS: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-fields`,
  CREATE_FIELD_NO_BOUNDARY: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-field/no-boundary`,
  CREATE_FIELD_SHAPE_FILE_UPLOADER: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-field/uploader`,
  CREATE_FIELD_SHAPE_FILE_LIST: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-field/uploaded-list`,
  CREATE_FIELD_DRAW: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-field/draw-field`,
  DETECT_FIELDS: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-field/detect-fields`,
  EDIT_FIELD_DRAW: ({ orgId, farmId, fieldId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/edit-field/${fieldId}`,
  EDIT_FIELD_DRAW_UPLOADED: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/edit-field/upload`,

  CREATE_SEASON: ({ orgId }) => `/${LANDING_ORG_ROUTE}/${orgId}/${SEASON_ROUTE}`,
  EDIT_SEASON: ({ orgId, seasonId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${SEASON_ROUTE}/${seasonId}`,
  RECOVERY_EMAIL_SENT: () => '/recovery-email-sent',
  ASSOCIATE_NEW_CROP: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/associate-new-crop`
};

export const publicItems = ['/', '/login', '/my-services', '/authenticate'];
